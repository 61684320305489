import axios from "axios";
import { allFilure, loginIn, spacificSusccess } from "./creatos.js";
import { allSusccess } from "./creatos.js";
import { allStarted } from "./creatos.js";



const BASE_URL= "https://preview.agatech.ru/admin";
// const BASE_URL= "/admin";

export const allPrev = () => async (dispatch) => {
    dispatch(allStarted())

    try {
      const { data } = await axios({
        url:`${BASE_URL}/lists.php`,
        method:"GET",
        header: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      })
      dispatch(allSusccess(data));
    } catch (error) {
    }
};
export const spacificPrev = ({ id }) => async (dispatch) => {
    dispatch(allStarted())
    try {
      const { data } = await axios.get(`${BASE_URL}/lists.php/?id=${id}`);
      console.log(data);
      dispatch(spacificSusccess(data));
    } catch (error) {
      console.log(error);
    }
};
export const SpacificChanges = ({ id }) => async (dispatch) => {
  try {
    const datas = new FormData();
    datas.append("changes", id);
    const { data } = await axios({
      url:`${BASE_URL}/changes.php`,
      method:"POST",
      data:datas,
      header: {
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    })    ;
    return data;
    
  } catch (error) {
    console.log(error);
  }
};
export const AddMaket = ({ id, prev }) => async (dispatch) => {
  try {
    const datas = new FormData();
    datas.append("add", id);
    datas.append(`maket`, prev[0]);
    console.log(datas);
    const { data } = await axios({
      url:`${BASE_URL}/changes.php`,
      method:"POST",
      data:datas,
      header: {
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    })
    if (data.indexOf("Запись прошла удачно")>= 0) {
      return true;
    }
    console.log(data);
  } catch (error) {
    // dispatch(allFilure())
  }
};
export const PrevUp = ({ id, prev }) => async (dispatch) => {
  try {
    const datas = new FormData();
    datas.append("previev", id);
    datas.append(`maket`, prev[0]);
    console.log(datas);
    const { data } = await axios({
      url:`${BASE_URL}/changes.php`,
      method:"POST",
      data:datas,
      header: {
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    })
    console.log(data);
    if (data.indexOf("Запись прошла удачно")>= 0) {
      return true;
    }
    console.log(data);
  } catch (error) {
  }
};

export const Dostups = ({ id }) => async (dispatch) => {
  try {
    const datas = new FormData();
    datas.append("dostup", id);
    console.log(datas);
    const { data } = await axios({
      url:`${BASE_URL}/changes.php`,
      method:"POST",
      data:datas,
      header: {
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    })
    if (data.indexOf("Запись прошла удачно")>= 0) {
      return true;
    }
    console.log(data);
  } catch (error) {
  }
};

export const DeleteProd = ({ id }) => async (dispatch) => {
  try {
    const datas = new FormData();
    datas.append("delete", id);
    datas.append("login", localStorage.getItem("name"));
    datas.append("password", localStorage.getItem("password"));
    datas.append("id_user", localStorage.getItem("id_user"));
    if (localStorage.getItem("name")) {
      const { data } = await axios({
        url:`${BASE_URL}/changes.php`,
        method:"POST",
        data:datas,
        header: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      })
      if (data.indexOf("Запись прошла удачно")>= 0) {
        return true;
      }
    }
    
    
  } catch (error) {
  }
};
export const LoginIn = ({form}) => async (dispatch) => {
  
  try {
    const { data } = await axios.get(`${BASE_URL}/login.php/?${form}`);
    
    
    
    // console.log(data);
   if (data.status==="true") {
    // console.log();

    localStorage.setItem("name",data.name);
    localStorage.setItem("password",data.password);
    localStorage.setItem("id_user",data.id);
    console.log(localStorage.getItem("name"));
    dispatch(loginIn(data))
    localStorage.setItem("statusLoginIn",true);
   }else{
    localStorage.setItem("statusLoginIn",false);

   }
    
    
  } catch (error) {
    // dispatch(allFilure())
  }
}

export const CreateProject = ({form,files,prev}) => async (dispatch) => {
  
  try {
    const json = JSON.stringify(form);
    const datas = new FormData();
    datas.append("document", json);
   for (let i = 0; i < files.length; i++) {
    datas.append(`files${i}`, files[i]);
   }
   datas.append(`preview`, prev[0]);
    console.log(datas);
    const { data } = await axios({
      url:`${BASE_URL}/createNewProject.php`,
      method:"POST",
      data:datas,
      header: {
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    })
    console.log(data);
    if (data.indexOf("Запись прошла удачно")>= 0) {
      return true;
    }

      

    
    
   
  } catch (error) {
    // dispatch(allFilure())
  }
}


export const UpdeteProject = ({form,files,prev}) => async (dispatch) => {
  
  try {
    const json = JSON.stringify(form);
    const datas = new FormData();
    datas.append("document", json);
   for (let i = 0; i < files.length; i++) {
    datas.append(`files${i}`, files[i]);
   }
   if (prev[0]!=undefined) {
   datas.append(`preview`, prev[0]);
    
   }
    console.log(datas);
    const { data } = await axios({
      url:`${BASE_URL}/changes.php`,
      method:"POST",
      data:datas,
      header: {
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data',
      },
    })
    console.log(data);
    if (data.indexOf("Запись прошла удачно")>= 0) {
      return true;
    }

      

    
    
   
  } catch (error) {
    // dispatch(allFilure())
  }
}


