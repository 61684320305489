import { useEffect, useState } from "react";
import { FormLogin, Header } from "../../components/header/header";
import { useThemeContext } from "../../context/theme";

export default function Entrance(params) {
  
  const { theme } = useThemeContext();

 
   
  
  return (
    <div style={{ background: theme.background, color: theme.color }}>
      <div className="container">
        <Header />
        <main
          style={{ background: theme.background, color: theme.color,display:"flex",alignItems:"center",justifyContent:"center",height:"100%" }}
          className="main "

        >
          <FormLogin />
        </main>
      </div>
    </div>
  );
}
