import { Link, NavLink, useLocation } from "react-router-dom";
import "./style.css";

import { useState } from "react";
import { useThemeContext } from "../../context/theme";
import serialize from "../function";
import { useDispatch, useSelector } from "react-redux";
import { LoginIn } from "../../stor/thunk";
import { todosSelector } from "../../stor/selectors";
import axios from "axios";
import { Proect } from "../../pages/proect/proect";
import { useEffect } from "react";
import Prints from "./print";

export function Header({ arr, setstate, state, name }) {
  const [switchBtn, setSwitchBtn] = useState(false);
  const [popup, setpopup] = useState(false);
  const data = useSelector(todosSelector);
  const [isTheme, setIsTheme] = useState(false);
  const { toggleTheme, theme } = useThemeContext();
  const [error, setIserror] = useState(false);
  const [list, setlist] = useState(1);

  // useEffect(() => {
  //     dispatch(UserOp())
  // }, [data.user])
  useEffect(() => {}, [popup]);
  const toggleIsTheme = () => {
    setIsTheme(!isTheme);
    toggleTheme();
    setSwitchBtn(!switchBtn);
  };
  if (arr) {
    document.title = "Agatech Preview - " + arr.name;
  }

  // console.log(localStorage.getItem("name"));
  function exit() {
    if (localStorage.getItem("name")) {
      localStorage.setItem("name","exit");
      localStorage.clear();

      window.localStorage.clear()
      window.location.reload();

    } else {
      setpopup(!popup);
    }
  }
  function LIstCheck(params) {
    if (list + params > 0 && list + params <= arr.media.length) {
      setstate(arr.media[list + params - 1].file_name);

      setlist(list + params);
    }
  }
  // console.log(list);
  return (
    <>
      <header
        style={{
          background: theme.background,
          color: theme.color,
          borderBottom: "2px solid #F3F3F3",
        }}
        className="header"
      >
        {!arr ? (
          <Link to="/" className="logo">
           {!theme.them? 
              <img
              className="logo"
              src={process.env.PUBLIC_URL + "/LogoBl.svg"}
              alt=""
            />:
            <img
            className="logo"
            src={process.env.PUBLIC_URL + "/Logo.svg"}
            alt=""
          />}
          </Link>
        ) : (
          <div className="header_list">
            <Link to="/" className="logo">
              {!theme.them? 
              <img
              className="logo"
              src={process.env.PUBLIC_URL + "/LogoBl.svg"}
              alt=""
            />:
            <img
            className="logo"
            src={process.env.PUBLIC_URL + "/Logo.svg"}
            alt=""
          />}
            </Link>
            <div className="header_lists">
              <select
                onChange={(e) => {
                  setstate(e.target.value);
                }}
                id="listPrev"
              >
                {arr.media.map((el, id) => (
                  <option data-id={id} key={id} value={el["file_name"]}>
                    {arr.name + "/" + el["translit"]}
                  </option>
                ))}
              </select>
            </div>
            <div className="slidePreviev">
              <span
                style={{
                  borderRightColor: theme.color,
                }}
                onClick={() => LIstCheck(-1)}
                className={list === 1 ? "left edge" : "left "}
              />
              {list}/{arr.media.length}
              <span
                style={{
                  borderLeftColor: theme.color,
                }}
                onClick={() => LIstCheck(1)}
                className={list === arr.media.length ? "right edge" : "right"}
              />
            </div>
          </div>
        )}
        <div className="header_menu">
          {localStorage.getItem("name") ? (
            <NavLink
              to="/create"
              style={{
                background: theme.background,
                color: theme.color,
                borderColor: theme.color,
              }}
              className="header_menu_criete"
            >
              + Добавить проект
            </NavLink>
          ) : (
            ""
          )}

          {!arr ? (
            <button
              onClick={exit}
              style={{
                background: theme.backgroundButton,
                color: theme.color2,
              }}
              className="entrance"
            >
              {!localStorage.getItem("name") ? "Войти" : "Выйти"}
            </button>
          ) : (
            <Prints img={"https://www.preview.agatech.ru/img/catalog/" +
            name +
            "/" +
            state} name={arr.name}/>
          )}
          <div
            onClick={toggleIsTheme}
            className={switchBtn ? "switch-btn" : "switch-btn switch-on"}
          ></div>
        </div>
      </header>

      <div
        onClick={() => setpopup(!popup)}
        className={popup ? "popup_loginIn active" : "popup_loginIn "}
      >
        <FormLogin
          setpopup={setpopup}
          popup={popup}
          error={error}
          setIserror={setIserror}
        />
      </div>
      
    </>
  );
}

export function FormLogin({ popup, setpopup, error, setIserror }) {
  const dispatch = useDispatch();
  let location = useLocation();

  function logIn(e) {
    e.preventDefault();

    let form = serialize(document.querySelector(".popup_loginIn_body"));
    dispatch(LoginIn({ form: form }));

    if (localStorage.getItem("statusLoginIn") === false) {
      setpopup(!popup);
      setIserror(false);
    } else {
      setIserror(true);
    }
  }
  // проверяем при кнопке входа залогинен ли пользователь усли да то чистим сессию иначе выводим форму входа

  return (
    <form
      onClick={(e) => e.stopPropagation()}
      onSubmit={logIn}
      className="popup_loginIn_body"
    >
      <div className="popup_loginIn_body_header">
        <h5>Вход</h5>{" "}
        <img className="" src={process.env.PUBLIC_URL + "/LogoBl.svg"} alt="" />
      </div>
      <div className="popup_loginIn_input_box">
        <input name="name" type="text" placeholder="Логин" />
      </div>
      <div className="popup_loginIn_input_box">
        <input name="password" type="password" placeholder="Пароль" />
      </div>
      <span className={error ? "errorLog active" : "errorLog "}>
        Неправильный логин или пароль
      </span>
      <button className="popup_loginIn_body_button">Войти</button>
      {!location.pathname === "/create" ? (
        <img
          onClick={() => setpopup(!popup)}
          className="exit"
          src={process.env.PUBLIC_URL + "/img/exit.svg"}
          alt=""
        />
      ) : (
        ""
      )}
    </form>
  );
}
function Print(print, name) {
 
  var printWindow = window.open(
    "1",
    name,
    "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
  );
  printWindow.document.write(Mel(print, name));
  printWindow.document.close();
  printWindow.print();
}
function Mel(params, name) {
  let logo = "https://www.preview.agatech.ru/header-IP.png";
  return `
    <!DOCTYPE html>
    <html lang="ru">
    <head>
    <meta charset="UTF-8">
    <title>Печать проекта - ${name} </title>
    <link rel="stylesheet" type="text/css" href="css/print.css">

    </head>
    <body style=" margin: 0; padding: 0; ">
    <div style="height:90px; width:100%;  text-align: center; padding-top:20px;
    box-sizing: border-box;">
    <img style="height:50px;
        max-width:100%;" src=${
          process.env.PUBLIC_URL + "/header-IP.png"
        } alt="Макет">
</div>
    
    <div style="max-height:850px; max-width:100%;  text-align: center; padding-top:20px;
        box-sizing: border-box;">
        <img style="max-height:100%;
        max-width:100%;" src=${params} alt="Макет">
    </div>
    
    </body>
    </html>
    
    `;
}
{
  /* <header
      style={{ justifyContent: child ? "space-between" : "space-around" }}
      className="header"
    >
      <Link to="/" className="logo">
        <img
          className="logo"
          src={process.env.PUBLIC_URL + "/Logo.svg"}
          alt=""
        />
      </Link>
      {child ? (
        <img
          onClick={() => Print(prints, data.specific[0].name)}
          className="header_print"
          src={process.env.PUBLIC_URL + "/print.svg"}
          alt=""
        />
      ) : (
        <div className="search">
          <input
            onChange={(event) => filter(event.target.value)}
            className="search_failed"
            type="text"
          />
          <img
            className="search_img"
            src={process.env.PUBLIC_URL + "/search.svg"}
            alt=""
          />
        </div>
      )}
    </header> */
}
