import React, { useContext } from "react";

export const themes = {
  light: {
    background: "#1D1D1D",
    backgroundButtom: "#FFFFFF",
    colorButtom: "#1D1D1D",
    color: "#FFFFFF",
    color2: "#1D1D1D",
    colorList: "#FFFFFF",
    backgroundButton:"#FFFFFF",
    borderBottom:"2px solid rgb(243 243 243 / 38%)",
    hover: "#EDEDED",
    button: "rgba(255, 255, 255, 0.1)",
    border:"1px solid #FFFFFF",
    backgroundList: "#FFFFFF1A",
    them:true,
  },
  dark: {
    background: "#FFFFFF",
    backgroundButtom: "#F3F3F3",

    color: "#000",
    color2: "#FFFFFF",
    colorList: "#999999",
    colorButtom: "#000",

    backgroundButton:"#0A1420",
    borderBottom:"#2px solid #000000000",

    hover: "#464646",
    button: "#F3F3F3",
    border:"1px solid #0A1420",
    backgroundList: "#F3F3F3",
    them:false,

  },
};
export const ThemeContext = React.createContext({
  theme: themes.dark,
  toggleTheme: () => {},
});
export const useThemeContext = () => {
  const theme = useContext(ThemeContext);

  if (!theme) return theme.dark;

  return theme;
};
