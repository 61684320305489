
import ErrorPage from "./pages/erroe/error";
import { Main } from "./pages/main/main";
import { Preview } from "./pages/preview";
import { ThemeContext, themes, useThemeContext } from "./context/theme";
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { todosSelector } from './stor/selectors';
import { allPrev } from './stor/thunk';
import { Proect } from "./pages/proect/proect";
import Entrance from "./components/entrance/entrance";
import { ProectChanges } from "./pages/proect/proectChanges";

  

export function AppRouste(params) {
  const [currentTheme, setCurrentTheme] = useState(themes.dark);

  const [fos, setfos] = useState(false);
  // const [fomName, setfomName] = useState(null);
  // const [formImg, setformImg] = useState(null);
  const { theme } = useThemeContext();
 
  const dispatch =useDispatch()
  const data = useSelector(todosSelector)      


  useEffect(() => {

    if (!data.all) {
      dispatch(allPrev({kat:1}))
    }


    
    }, []);
    useEffect(()=>{
    },[fos])

  const toggleTheme = () => {
    if (currentTheme === themes.dark) {
      setCurrentTheme(themes.light);
      return;
    }
    setCurrentTheme(themes.dark);
  };
  
  const router = createBrowserRouter([
    {
      path: "/",
      // errorElement:<><ErrorPage/></>,
      element: < ><Main /></>,
    },
    {
      path: "/preview/:id",
      // errorElement:<><ErrorPage/></>,
      element: <><Preview /></>,
    },
    {
      path: "*",
      errorElement:<><ErrorPage/></>,
      element: <><ErrorPage/></>,
    },
    {
      path: "/editing/:id",
      // errorElement: <><ErrorPage/></>,
      element:<><ProectChanges/></>,
    },
    {
      path: "/create",
      // errorElement: <><ErrorPage/></>,
      element:localStorage["name"]? <Proect />:<Entrance/>,
    },
    
  ]);
    return   (
      <ThemeContext.Provider value={{ theme: currentTheme, toggleTheme }}>
       <RouterProvider router={router}/>
     </ThemeContext.Provider>
     
    )

}
