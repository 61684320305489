import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {  NavLink, useLocation, useParams } from "react-router-dom";
import { Header } from "../../components/header/header";
import { MiniShow } from "../../components/mini_showcase";
import { todosSelector } from "../../stor/selectors";
import { spacificPrev } from "../../stor/thunk";
import "./style.css"
import { useThemeContext } from "../../context/theme";
import Preloader from "../preloader/preloader";
export function Preview() {
    const { theme } = useThemeContext();
    const loc = useLocation();
    const params = useParams();
    const [state, setstate] = useState(null);
    const [collapse,setCollapse]=useState(false);
    const discpatch =useDispatch()
    const data = useSelector(todosSelector)
    useEffect(() => {
       if (!data.specific) {
        discpatch(spacificPrev({id:params.id}))
       }
    }, [ data]);
   
    if (!data.specific) {
        return <Preloader/>;
    }
    if (state==null) {
        setstate(data.specific.media[0]["file_name"])
    }
    // console.log(data);
    return(
        <div>
            <Header name={data.specific.translit} state={state} setstate={setstate} arr={data.specific} />
            {/* <main style={!collapse?{gridTemplateColumns:`3fr 20%`}:{gridTemplateColumns:`3fr 0px`}}  className="showcase"> */}
            <main style={{gridTemplateColumns:`3fr 0px`}}  className="showcase">
                <div className="showcase_img_box">
                    <img id="tr" onLoad={()=>qwe({setCollapse:setCollapse})} className={collapse?"showcase_img img_mini":"showcase_img"} src={"https://www.preview.agatech.ru/img/catalog/"+data.specific.translit+'/'+state} alt="" />
                </div>
                {/* <div className="right_box">
                    <div className={!collapse? "right_gallery":"right_gallery hidden"}>
                        <div className="right_gallery_box">
                            <button className={!collapse? "hide":"bec"} onClick={()=>setCollapse(!collapse)}><img className="hide_img" src={process.env.PUBLIC_URL + "/strel.svg"} alt="" /></button>
                            {data.specific[0].media.map((element, id)=>
                            <MiniShow setstate={setstate} key={id} name={element} id={id} />)}
                        </div>
                    </div>
                </div> */}
                  
                
            </main>
            <div onClick={() => {window.scrollTo({top:0,behavior: "smooth"})}} className="upWind">
                <img className="upWind_img"  src={process.env.PUBLIC_URL + "/up1.svg"} alt="" />
                <img className="upWind_img2"  src={process.env.PUBLIC_URL + "/up2.svg"} alt="" />
                {/* <p className="upWind_text">Наверх</p> */}
            </div>
        </div>
    )
}

function qwe({setCollapse}) {
    
    if (document.querySelector("#tr").naturalWidth<500) {
        setCollapse(true);
    }else{
        setCollapse(false);
    }
}
function Beak(params) {
    return(
        <NavLink  to="/">
            
        </NavLink>
        
    )
}
