


export default function Span({missage,color,fal=false,setfel}) {
    
    return (
        <>
        {fal ?
            <div onClick={()=>setfel(!fal)} className="massege">
                {missage}
            </div>:""}
            
            </>
    )
}