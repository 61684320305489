import { useState } from "react";
import "./changes.css";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import { AddMaket, DeleteProd, PrevUp } from "../../stor/thunk";
import Span from "../span/span";

export function MaketUP({ id, on, setOn }) {
  const [preview, setPreview] = useState(null);
  const [massage, setmassage] = useState(false);
  const inps = useRef(null);
  const dispatch = useDispatch();
  function UP(e) {
    e.preventDefault();

    // console.log(document.querySelector(".UP input").files);
    dispatch(
      AddMaket({
        id: id,
        prev: document.querySelector(".UP input").files,
      })
    ).then((response) => {
      if (response === true) {
        
        setmassage(!massage);
        setTimeout(() => {
          setOn(!on);
          window.location.reload();
        }, 3000);
      }
    });
  }
  function But(params) {
    console.log("but");
    document.querySelector(".UP label").click();
  }
  return (
    <>
      {on ? (
        <div
          onClick={() => setOn(!on)}
          className={on ? "maket_changes active" : "maket_changes "}
        >
          <form
            onClick={(e) => e.stopPropagation()}
            className="maket_changes_form UP"
            onSubmit={UP}
            action=""
            encType="multipart/form-data"
            multiple
          >
            <h3>Добавить макет в проект</h3>

            <label className="input-file">
              <input
                onChange={(e) =>
                  setPreview(URL.createObjectURL(e.target.files[0]))
                }
                ref={inps}
                type="file"
                name="file"
                accept="image/*"
                required
              />
              <span
                style={{
                  backgroundRepeat: "no-repeat",
                  backgroundImage: `url(${preview})`,
                  backgroundSize: "contain",
                }}
              >
                {!preview ? " Добавить макет " : ""}
                {!preview ? (
                  <img src={process.env.PUBLIC_URL + "/img/exit.svg"} alt="" />
                ) : (
                  ""
                )}
              </span>
            </label>

            <button onClick={preview === null ? But : UP} className="entrance">
              {preview === null ? "Выбрать макет" : "Сохранить и продолжить"}
            </button>
          </form>
          <Span fal={massage} missage={"Запись прошла успешно!"} setfel={setmassage}/>

        </div>
      ) : (
        ""
      )}
    </>
  );
}
export function PrevievUP({ id, on, setOn }) {
  const [preview, setPreview] = useState(null);
  const [massage, setmassage] = useState(false);
  const inps = useRef(null);
  const dispatch = useDispatch();
  function UP(e) {
    e.preventDefault();

    // console.log(document.querySelector(".UP input").files);
    dispatch(
      PrevUp({
        id: id,
        prev: document.querySelector(".UP input").files,
      })
    ).then((response) => {
      if (response === true) {
        
        setmassage(!massage);
        setTimeout(() => {
          setOn(!on);
          window.location.reload();
        }, 3000);
      }
    });
  }
  function But(params) {
    console.log("but");
    document.querySelector(".UP label").click();
  }
  return (
    <>
      {on ? (
        <div
          onClick={() => setOn(!on)}
          className={on ? "maket_changes active" : "maket_changes "}
        >
          <form
            onClick={(e) => e.stopPropagation()}
            className="maket_changes_form UP"
            onSubmit={UP}
            action=""
            encType="multipart/form-data"
            multiple
          >
            <h3>Изменить превью проекта</h3>

            <label className="input-file">
              <input
                onChange={(e) =>
                  setPreview(URL.createObjectURL(e.target.files[0]))
                }
                ref={inps}
                type="file"
                name="file"
                accept="image/*"
                required
              />
              <span
                style={{
                  backgroundRepeat: "no-repeat",
                  backgroundImage: `url(${preview})`,
                  backgroundSize: "contain",
                }}
              >
                {!preview ? " Добавить макет " : ""}
                {!preview ? (
                  <img src={process.env.PUBLIC_URL + "/img/exit.svg"} alt="" />
                ) : (
                  ""
                )}
              </span>
            </label>

            <button onClick={preview === null ? But : UP} className="entrance">
              {preview === null ? "Выбрать макет" : "Сохранить и продолжить"}
            </button>
          </form>
          <Span fal={massage} missage={"Изменения прошли удачно!"} setfel={setmassage}/>

        </div>
      ) : (
        ""
      )}
    </>
  );
}

export function DeleteProject({ id, on, setOn }) {
  const [ok, setOk] = useState(false);
  const [massage, setmassage] = useState(false);
  const inps = useRef(null);
  const dispatch = useDispatch();
  function UP(e) {
    if (ok===true) {
      dispatch(
        DeleteProd({
          id: id,
        })
      ).then((response) => {
        if (response === true) {
          console.log(response);
          setmassage(!massage);
          setTimeout(() => {
            setOn(!on);
            window.location.reload();
          }, 3000);
        }
      });
    }
    
  }
  function But(params) {
    setOk(!ok);
  }


  return (
    <>
      {on ? (
        <div
          onClick={() => setOn(!on)}
          className={on ? "maket_changes active" : "maket_changes "}
        >
          <form
            onClick={(e) => e.stopPropagation()}
            className="maket_changes_form UP"
            onSubmit={(e)=> e.preventDefault()}
            action=""
            encType="multipart/form-data"
            multiple
          >
            
            {ok === false ? <h3>Удалить проект?</h3> : <h3> Вы уверены что хотите удалить проет?</h3>

            }
           
            <div className="flex">
            <button onClick={ok === false ? But : UP} className="entrance">
              {ok === false ? "Да" : "Да, уверен(на)"}
            </button>
            <button onClick={()=>{setOk(false);setOn(false)}} className="entrance">
              {'Отмена'}
            </button>
            </div>
            
          </form>
          <Span fal={massage} missage={"Изменения прошли удачно!"} setfel={setmassage}/>

        </div>
      ) : (
        ""
      )}
    </>
  );
}