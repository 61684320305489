import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Header } from "../../components/header/header";
import { SpacificChanges, UpdeteProject } from "../../stor/thunk";
import "./proect.css";
import { useThemeContext } from "../../context/theme";
import { useRef } from "react";
import GallariRedact, { ImageUploadPreviewComponent } from "./class";
import Span from "../../components/span/span";
import App from "./gallari";
import { useParams } from "react-router-dom";

export function ProectChanges() {
  const discpatch = useDispatch();
  const params = useParams();
  const [selectedImages, setSelectedImages] = useState([]);
  const [delImages, setDelImages] = useState([]);
  const [project, setproject] = useState(null);
  const [preview, setPreview] = useState();
  const { theme } = useThemeContext();
  const [massage, setmassage] = useState(false);
  const input = useRef(null);
  const link = useRef(null);

  useEffect(() => {
    if (project === null) {
      discpatch(
        SpacificChanges({
          id: params.id,
        })
      ).then((response) => {
        setproject(response);
      });
    }
    if (project != null) {
      if (preview == null) {
        setPreview(
          `https://preview.agatech.ru/img/catalog/${project.product[0].translit}/${project.product[0].previw}`
        );
      }
    }
  }, [project]);

  function update(e) {
    e.preventDefault();
    if (localStorage.getItem("name")) {
      let obj = {};
      // имя
      console.log(e);
      obj["id"] = params.id;
      obj["user_id"] = project.product[0].user_id;

      obj["name"] = e.target[1].value;
      // данные пользователя
      obj["login"] = localStorage.getItem("name");
      obj["password"] = localStorage.getItem("password");
      obj["opis"] = e.target[10].value;

      // вид доступа
      for (let i = 11; i < 13; i++) {
        if (e.target[i].checked) {
          obj["dostup"] = e.target[i].value;
        }
      }
      // ссылка фигмы
      obj["link"] = e.target[13].value;
      // дата началы работы над проектом
      obj["date"] = e.target[14].value;

      let a = [];
      // выбранные категории

      for (let i = 2; i < 8; i++) {
        if (e.target[i].checked) {
          a += e.target[i].dataset.type + ",";
        }
      }
      if (a == []) {
        a = e.target[6].dataset.type;
      }

      obj["kategor"] = a;
      obj["deleteImg"] = delImages;
      // let form = serialize(document.querySelector(".proect_form"));

      document.querySelector(".proces").classList.remove("stop");
      discpatch(
        UpdeteProject({
          form: obj,
          files: selectedImages,
          prev: e.target[0].files,
        })
      ).then((response) => {
        if (response === true) {
          setmassage(!massage);
          document.querySelector(".proces").classList.add("stop");
        }
      });
    } else {
      alert("Войдите в профиль");
    }
  }
  if (project === null) {
    return <div></div>;
  } else {
    document.querySelectorAll(".checKat").forEach((e, i) => {
      console.log(e);
      for (let j = 0; j < project.product[0].cat_id.length; j++) {
        if (project.product[0].cat_id[j]=== e.dataset["type"]) {

          e.checked = true;
          
        }
        
      }
      // if (
      //   project.product[0].cat_id.indexOf(i + 1) >= i ||
      //   project.product[0].cat_id == i + 1
      // ) {
      //   console.log(i);
      //   e.checked = true;
      // }
    });
    document.querySelectorAll(".checDostup").forEach((element) => {
      if (project.product[0].status === element.value) {
        element.checked = true;
      }
    });
  }
  return (
    <div style={{ background: theme.background, color: theme.color }}>
      <div className="container">
        <Header />
        <main
          style={{ background: theme.background, color: theme.color }}
          className="main "
        >
          <div className="proect-main">
            <a
              style={{ background: theme.background, color: theme.color }}
              href="/"
              className="proect_no"
            >
              <img
                style={{ filter: theme.them ? "invert(1)" : "" }}
                src={process.env.PUBLIC_URL + "/strel.svg"}
                alt=""
              />
              Вернуться назад
            </a>
          </div>
          <section className="proect">
            <form
              className="proect_form"
              onSubmit={update}
              action=""
              encType="multipart/form-data"
              multiple
            >
              <div className="proect_head">
                <div className="proect_prev">
                  <label className="input-file">
                    <input
                      onChange={(e) =>
                        setPreview(URL.createObjectURL(e.target.files[0]))
                      }
                      type="file"
                      name="files"
                      accept="image/*"
                    />
                    <span
                      style={{
                        backgroundImage: `url(${preview})`,
                        backgroundPosition: "center center",
                        color:theme.colorButtom,
                        
                      }}
                    >
                      {!preview ? " Добавить превью " : ""}
                      {!preview ? (
                        <img
                          src={process.env.PUBLIC_URL + "/img/exit.svg"}
                          alt=""
                        />
                      ) : (
                        ""
                      )}
                    </span>
                  </label>
                </div>
                <div className="proect_info">
                  <div className="proect_name">
                    <h1>Управление проектом</h1>
                    <h3>Введите название проекта</h3>
                    <div
                      style={{
                        background: theme.backgroundButtom,
                        color: theme.colorButtom,
                      }}
                      className="proect_name_input"
                    >
                      <input
                        ref={input}
                        onFocus={() => input.current.select()}
                        type="text"
                        name="name"
                        defaultValue={project.product[0].name}
                      />
                      <img
                        onClick={() =>
                          document
                            .querySelector(".proect_name_input input")
                            .focus()
                        }
                        src={process.env.PUBLIC_URL + "/img/red.svg"}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="proect_chek">
                    <h3>Выберете теги для проекта</h3>

                    <div className="proect_chek_items">
                      <div className="proect_chek_item">
                        <input
                          className="checKat"
                          type="checkbox"
                          name="checkbox"
                          data-type="1"
                          id="internet_magazin"
                        />
                        <label htmlFor="internet_magazin">
                          Интернет-магазин{" "}
                          <img
                            src={process.env.PUBLIC_URL + "/img/exit.svg"}
                            alt=""
                          />
                        </label>
                      </div>
                      <div className="proect_chek_item">
                        <input
                          className="checKat"
                          type="checkbox"
                          name="checkbox"
                          data-type="2"
                          id="lending"
                        />
                        <label htmlFor="lending">
                          Лендинг{" "}
                          <img
                            src={process.env.PUBLIC_URL + "/img/exit.svg"}
                            alt=""
                          />
                        </label>
                      </div>
                      <div className="proect_chek_item">
                        <input
                          className="checKat"
                          type="checkbox"
                          name="checkbox"
                          data-type="3"
                          id="Korp_site"
                        />
                        <label htmlFor="Korp_site">
                          Корп. сайт{" "}
                          <img
                            src={process.env.PUBLIC_URL + "/img/exit.svg"}
                            alt=""
                          />
                        </label>
                      </div>
                      <div className="proect_chek_item">
                        <input
                          className="checKat"
                          type="checkbox"
                          name="checkbox"
                          data-type="4"
                          id="promosaite"
                        />
                        <label htmlFor="promosaite">
                          Промосайт{" "}
                          <img
                            src={process.env.PUBLIC_URL + "/img/exit.svg"}
                            alt=""
                          />
                        </label>
                      </div>
                      <div className="proect_chek_item">
                        <input
                          className="checKat"
                          type="checkbox"
                          name="checkbox"
                          data-type="6"
                          id="perspectiv"
                        />
                        <label htmlFor="perspectiv">
                          Перспективный{" "}
                          <img
                            src={process.env.PUBLIC_URL + "/img/exit.svg"}
                            alt=""
                          />
                        </label>
                      </div>
                      <div className="proect_chek_item">
                        <input
                          className="checKat"
                          type="checkbox"
                          name="checkbox"
                          data-type="5"
                          id="ostalnoe"
                        />
                        <label htmlFor="ostalnoe">
                          Остальное{" "}
                          <img
                            src={process.env.PUBLIC_URL + "/img/exit.svg"}
                            alt=""
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="proect_makets">
                <h3>Макеты</h3>
                <div className="proect_makets">
                  <GallariRedact
                    media={project}
                    delImages={delImages}
                    setDelImages={setDelImages}
                    selectedImages={selectedImages}
                    setSelectedImages={setSelectedImages}
                  />
                </div>
              </div>
              <div className="proect_opis">
                <h3>Краткое описание</h3>
                <textarea
                  defaultValue={project.product[0].opisanie}
                  name="textarea"
                  id=""
                  cols="30"
                  rows="10"
                ></textarea>
              </div>
              <div className="proect_dostup">
                <h3>Вид доступа</h3>

                <div>
                  <label htmlFor="open">
                    <input
                      className="checDostup"
                      defaultValue="0"
                      type="radio"
                      name="dostup"
                      id="open"
                    />
                    открытый
                  </label>
                  <label htmlFor="private">
                    <input
                      className="checDostup"
                      defaultValue="1"
                      type="radio"
                      name="dostup"
                      id="private"
                    />
                    приватный
                  </label>
                </div>
              </div>
              <div className="proect_link">
                <h3>Ссылка на фигму(будет видна только в приватном доступе)</h3>
                <div className="proect_name_input">
                  <input
                    ref={link}
                    onFocus={() => link.current.select()}
                    type="text"
                    name="link"
                    required
                    defaultValue={project.product[0].figma}
                  />
                  <img
                    onClick={() =>
                      document.querySelector(".proect_link input").focus()
                    }
                    src={process.env.PUBLIC_URL + "/img/red.svg"}
                    alt=""
                  />
                </div>
              </div>
              <div className="proect_data">
                <h3>Дата началы работы с проектом</h3>
                <div>
                  <input
                    defaultValue={project.product[0].data.slice(0, -9)}
                    type="date"
                    name="date"
                    required
                  />
                </div>
              </div>
              <button
                style={{
                  background: theme.backgroundButtom,
                  color: theme.colorButtom,
                }}
                className="entrance"
              >
                Сохранить изменения <div className="proces stop"></div>
              </button>
            </form>
          </section>
          <Span
            fal={massage}
            missage={"Запись прошла успешно!"}
            setfel={setmassage}
          />
          {/* <div className={preview? "span":"span active"}   >
            Войдите в профиль
        </div> */}
        </main>
      </div>
    </div>
  );
}
