import { STARTED, SUCCESS,FILURE, SPECIFIC,LOGIN_IN } from "./types";


export const allStarted=()=>({
    type:STARTED,
})
export const allSusccess=(todos)=>({
    type:SUCCESS,
    payload:{
        todos:{
        all:todos,
            
        }
    }
})


export const spacificSusccess=(todos)=>({
    type:SPECIFIC,
    payload:{
        todos:{
        specific:todos,
            
        }
    }
})
export const allFilure=()=>({
    type:FILURE,
})
export const loginIn=(todos)=>({
    type:LOGIN_IN,
    payload:{
        todos:{
            user:todos,
        }
    }
})