import "./preloader.css";

export default function Preloader(params) {
   
    return (
        <>
            <div className="preloader">
                <div className="pl">
                    <div className="pl_box">
                        <div className="spinner"></div>
                        <div className="spinner_two"></div>
                        <div className="spinner_two trree"></div>
                    </div>
                    <div className="pl__nucleus">
                        <img
                            loading="lazy"
                            src={process.env.PUBLIC_URL + "/logo_prelouder.png"}
                            alt=""
                        />
                    </div>
                </div>
            </div>

            {/* <div className="preloader">
            <div className="spinner-box">
                <div className="blue-orbit leo"></div>

                <div className="green-orbit leo"></div>

                <div className="red-orbit leo"></div>

                <div className="white-orbit w1 leo"></div>
                <div className="white-orbit w2 leo"></div>
                <div className="white-orbit w3 leo"></div>
            </div>
        </div> */}
        </>
    );
}
