import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Header } from "../../components/header/header";
import { PreviewMini } from "../../components/saite_prev/PreviewMini";
import { todosSelector } from "../../stor/selectors";
import { CreateProject, SpacificChanges, allPrev } from "../../stor/thunk";
import "./proect.css";
import { useThemeContext } from "../../context/theme";
import { useRef } from "react";
import { ImageUploadPreviewComponent } from "./class";
import Span from "../../components/span/span";
import App from "./gallari";

export function Proect() {
  const discpatch = useDispatch();

 

  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();
  const { theme } = useThemeContext();
  const [massage, setmassage] = useState(false);
  const input = useRef(null);
  const link = useRef(null);
  const data = useSelector(todosSelector);




  function create(e) {
    e.preventDefault();
    if (localStorage.getItem("name")) {
      let obj = {};
      // имя
      console.log(e);
      obj["name"] = e.target[1].value;
      // данные пользователя
      obj["login"] = localStorage.getItem("name");
      obj["password"] = localStorage.getItem("password");
      obj["opis"] = e.target[10].value;

      // вид доступа
      for (let i = 11; i < 13; i++) {
        if (e.target[i].checked) {
          obj["dostup"] = e.target[i].value;
          
        }
        
      }
      // ссылка фигмы
      obj["link"] = e.target[13].value;
      // дата началы работы над проектом
      obj["date"] = e.target[14].value;
     
      let a = [];
      // выбранные категории
      for (let i = 2; i < 8; i++) {
        if (e.target[i].checked) {
          a+= e.target[i].dataset.type+",";
        }
      }
      if (a == []) {
        a = e.target[6].dataset.type;
      }
      
      obj["kategor"] = a;
      // let form = serialize(document.querySelector(".proect_form"));
      
      console.log(selectedImages);
      console.log(obj);
      console.log(e);

      document.querySelector(".proces").classList.remove("stop");
        discpatch(
          CreateProject({
            form: obj,
            files: selectedImages,
            prev: e.target[0].files,
          })
        ).then((response)=>{
              if (response===true) {
                setmassage(!massage);
                document.querySelector(".proces").classList.add("stop");
              }
            })
    } else {
      alert("Войдите в профиль");
    }
  }
  
  return (
    <div style={{ background: theme.background, color: theme.color }}>
      <div className="container">
        <Header />
        <main
          style={{ background: theme.background, color: theme.color }}
          className="main "
        >
          <div className="proect-main">
            <a
              style={{ background: theme.background, color: theme.color }}
              href="/"
              className="proect_no"
            >
              <img
                style={{ filter: theme.them ? "invert(1)" : "" }}
                src={process.env.PUBLIC_URL + "/strel.svg"}
                alt=""
              />
              Вернуться назад
            </a>
          </div>
          <section className="proect">
            <form
              className="proect_form"
              onSubmit={create}
              action=""
              encType="multipart/form-data"
              multiple
            >
              <div className="proect_head">
                <div className="proect_prev">
                  <label className="input-file">
                    <input
                      onChange={(e) =>
                        setPreview(URL.createObjectURL(e.target.files[0]))
                      }
                      type="file"
                      name="file"
                      accept="image/*"
                      required
                    />
                    <span
                      style={{
                        backgroundImage: `url(${preview})`,
                        backgroundPosition: "center center",
                        color:theme.colorButtom,
                      }}
                    >
                      {!preview ? " Добавить превью " : ""}
                      {!preview ? (
                        <img
                          src={process.env.PUBLIC_URL + "/img/exit.svg"}
                          alt=""
                        />
                      ) : (
                        ""
                      )}
                    </span>
                  </label>
                </div>
                <div className="proect_info">
                  <div className="proect_name">
                    <h1>Управление проектом</h1>
                    <h3>Введите название проекта</h3>
                      
                    <div style={{ background: theme.backgroundButtom, color: theme.colorButtom }} className="proect_name_input">
                      <input
                        ref={input}
                        onFocus={() => input.current.select()}
                        type="text"
                        name="name"
                        required
                        // defaultValue="Ивашевская вода"
                      />
                      <img
                        onClick={() =>
                          document
                            .querySelector(".proect_name_input input")
                            .focus()
                        }
                        src={process.env.PUBLIC_URL + "/img/red.svg"}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="proect_chek">
                    <h3>Выберете теги для проекта</h3>

                    <div className="proect_chek_items">
                      <div className="proect_chek_item">
                        <input
                          type="checkbox"
                          name="checkbox"
                          data-type="1"
                          id="internet_magazin"
                        />
                        <label htmlFor="internet_magazin">
                          Интернет-магазин{" "}
                          <img
                            src={process.env.PUBLIC_URL + "/img/exit.svg"}
                            alt=""
                          />
                        </label>
                      </div>
                      <div className="proect_chek_item">
                        <input
                          type="checkbox"
                          name="checkbox"
                          data-type="2"
                          id="lending"
                        />
                        <label htmlFor="lending">
                          Лендинг{" "}
                          <img
                            src={process.env.PUBLIC_URL + "/img/exit.svg"}
                            alt=""
                          />
                        </label>
                      </div>
                      <div className="proect_chek_item">
                        <input
                          type="checkbox"
                          name="checkbox"
                          data-type="3"
                          id="Korp_site"
                        />
                        <label htmlFor="Korp_site">
                          Корп. сайт{" "}
                          <img
                            src={process.env.PUBLIC_URL + "/img/exit.svg"}
                            alt=""
                          />
                        </label>
                      </div>
                      <div className="proect_chek_item">
                        <input
                          type="checkbox"
                          name="checkbox"
                          data-type="4"
                          id="promosaite"
                        />
                        <label htmlFor="promosaite">
                          Промосайт{" "}
                          <img
                            src={process.env.PUBLIC_URL + "/img/exit.svg"}
                            alt=""
                          />
                        </label>
                      </div>
                      <div className="proect_chek_item">
                        <input
                          type="checkbox"
                          name="checkbox"
                          data-type="6"
                          id="perspectiv"
                        />
                        <label htmlFor="perspectiv">
                          Перспективный{" "}
                          <img
                            src={process.env.PUBLIC_URL + "/img/exit.svg"}
                            alt=""
                          />
                        </label>
                      </div>
                      <div className="proect_chek_item">
                        <input
                          type="checkbox"
                          name="checkbox"
                          data-type="5"
                          id="ostalnoe"
                        />
                        <label htmlFor="ostalnoe">
                          Остальное{" "}
                          <img
                            src={process.env.PUBLIC_URL + "/img/exit.svg"}
                            alt=""
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="proect_makets">
                <h3>Макеты</h3>
                <div className="proect_makets">
                  <App selectedImages={selectedImages} setSelectedImages={setSelectedImages}/>
                </div>
              </div>
              <div className="proect_opis">
                <h3>Краткое описание</h3>
                <textarea name="textarea" id="" cols="30" rows="10"></textarea>
              </div>
              <div className="proect_dostup">
                <h3>Вид доступа</h3>

                <div>
                  <label htmlFor="open">
                    <input defaultValue="0" type="radio" name="dostup" id="open" />
                    открытый
                  </label>
                  <label htmlFor="private">
                    <input defaultValue="1"  type="radio" name="dostup" id="private" />
                    приватный
                  </label>
                </div>
              </div>
              <div className="proect_link">
                <h3>Ссылка на фигму(будет видна только в приватном доступе)</h3>
                <div style={{ background: theme.backgroundButtom, color: theme.colorButtom }} className="proect_name_input">
                  <input
                    ref={link}
                    onFocus={() => link.current.select()}
                    type="text"
                    name="link"
                    required
                  />
                  <img
                    onClick={() =>
                      document.querySelector(".proect_link input").focus()
                    }
                    src={process.env.PUBLIC_URL + "/img/red.svg"}
                    alt=""
                  />
                </div>
              </div>
              <div className="proect_data">
              <h3>Дата началы работы с проектом</h3>
                  <div>
                  <input
                    type="date"
                    name="date"
                    required
                  />
                  </div>
                  
                </div>
              <button style={{ background: theme.backgroundButtom, color: theme.colorButtom }} className="entrance">Сохранить изменения  <div className="proces stop"></div></button>
            </form>
          </section>
          <Span fal={massage} missage={"Запись прошла успешно!"} setfel={setmassage}/>
          {/* <div className={preview? "span":"span active"}   >
            Войдите в профиль
        </div> */}
        </main>
      </div>
    </div>
  );
}
