import { useState } from "react";

const GallariRedact = (params) => {
    const [selectedImages, setSelectedImages] = useState([]);
    const onSelectFile = (event) => {
    const selectedFiles = event.target.files;
    const selectedFilesArray = Array.from(selectedFiles);

    params.setSelectedImages((previousImages) =>
      previousImages.concat(selectedFilesArray)
    );
    const imagesArray = selectedFilesArray.map((file) => {
      return URL.createObjectURL(file);
    });

    setSelectedImages((previousImages) => previousImages.concat(imagesArray));

    event.target.value = "";
  };

  function deleteHandler(image, index) {
    setSelectedImages(selectedImages.filter((e) => e !== image));
    params.setSelectedImages(
      params.selectedImages.filter((e, i) => i !== index)
    );

    URL.revokeObjectURL(image);
  }
  function deleteImg(index) {
    let a = 0;
    params.delImages.forEach((e,i) => {
        if (e===index) {
            a++;
        }})
        if (a===0) {
    params.setDelImages((previousImages) => previousImages.concat(index));
            
        }
  // console.log(params.delImages);
    
  }
  // console.log(params.delImages);

  return (
    <section className="input-file-row">
      <label className="input-file">
        <span>
          Добавить макет{" "}
          <img src={process.env.PUBLIC_URL + "/img/exit.svg"} alt="" />
        </span>
        <input
          type="file"
          name="images"
          onChange={onSelectFile}
          multiple
          accept="image/png , image/jpeg, image/webp"
        />
      </label>

      <input type="file" multiple />

      {/* {selectedImages.length > 0 &&
          (selectedImages.length > 10 ? (
            <p className="error">
              You can't upload more than 10 images! <br />
              <span>
                please delete <b> {selectedImages.length - 10} </b> of them{" "}
              </span>
            </p>
          ) : (
            <button
              className="upload-btn"
              onClick={() => {
                console.log(selectedImages);
              }}
            >
              UPLOAD {selectedImages.length} IMAGE
              {selectedImages.length === 1 ? "" : "S"}
            </button>
          ))} */}

      <div className="input-file-list">
        {selectedImages &&
          selectedImages.map((image, index) => {
            return (
              <div key={image} className="input-file-list-item">
                <img src={image} alt="upload" />
                <span
                  className="input-delete"
                  onClick={() => deleteHandler(image, index)}
                >
                  Удалить
                </span>
                <p>{"NEW"}</p>
              </div>
            );
          })}
        {params.media &&
          params.media.media.map((el, index) => {
            if (!params.delImages.includes(el.id)) {
                return(
                    <div key={el.id} className="input-file-list-item">
                <img
                  src={`/img/catalog/${params.media.product[0].translit}/${el.file_name}`}
                  alt="upload"
                />
                <span
                  className="input-delete"
                    onClick={() => deleteImg(el.id)}
                >
                  Удалить
                </span>
                <p>{"старое"}</p>
              </div>
                )
            }

             
              
          })}
      </div>
    </section>
  );
};

export default GallariRedact;
