import { STARTED, SUCCESS, FILURE, SPECIFIC,LOGIN_IN } from "./types";

const initialState = {
    loading: false,
    error: null,
    todos: {user:{}},
  };

  export default function todoReducer(state = initialState, action) {
    switch (action.type) {
      case FILURE: {
        return {
          ...state,
          loading: false,
          error: action.payload.error,
        };
      }
  
      case STARTED: {
        return {
          ...state,
          loading: true,
        };
      }
  
      case SUCCESS:
        return {
          ...state,
          loading: false,
          error: null,
  
          todos: {
            all: [...action.payload.todos.all],
            user: {...state.todos.user},
            
          },
      };

      case SPECIFIC:
        return {
            ...state,
            loading: false,
            error: null,
    
            todos: {
              // all: [...state.todos.all],
              specific: {...action.payload.todos.specific},
              user: {...state.todos.user},

            },
      };
      case LOGIN_IN: 
      return{
            ...state,
            loading: false,
            error: null,
    
            todos: {
              user: {...action.payload.todos.user},
              // all: [...state.todos.all],
              // specific: [...state.todos.specific],
              

            },
      }
      
  
     
  
      default:
        return state;
    }
  }
  