import { Link, NavLink } from "react-router-dom";
import { Preview } from "../../pages/preview";
import "./PreviewMini.css";
import { useDispatch, useSelector } from "react-redux";
import { todosSelector } from "../../stor/selectors";
import { useEffect, useState } from "react";
import { DeleteProject, MaketUP, PrevievUP } from "../Changes/changes";
import { Dostups } from "../../stor/thunk";

export function PreviewMini(params) {
  const data = useSelector(todosSelector);
  let catt = params.cat_id.split(",");
  const [on, setOn] = useState(false);
  const [prevon, setPrevon] = useState(false);
  const [del, setDel] = useState(false);
  useEffect(() => {}, [on]);
  const dispatch = useDispatch();
  function Dostup(id) {
    dispatch(
      Dostups({
        id: id,
      })
    ).then((response) => {
      if (response === true) {
        console.log("ok");
      }
    });
  }

  // console.log(params);
  // console.log(localStorage.getItem("id_user"));
  return (
    <>
      <div
        className="preview"
        to={`preview/${params.id}`}
        element={<Preview />}
        key={params.id}
      >
        <NavLink
          to={`preview/${params.id}`}
          element={<Preview />}
          className="preview_img_box"
          style={{
            background: `url(https://www.preview.agatech.ru/img/catalog/${params.img}) no-repeat`,
            backgroundSize: "cover",
            backgroundPosition: "center center",
          }}
        ></NavLink>
        <p style={{ color: params.theme.color }} className="preview_text">
          {params.name}
        </p>
        <div className="preview_list">
          {catt.map((e, i) => (
            <CatId theme={params.theme} key={i} cat={e} />
          ))}
        </div>
        {localStorage.getItem("id_user") == params.user ||
        localStorage.getItem("id_user") == 1 ? (
         <>
          <div className="preview_menu">
            <div onClick={() => setOn(!on)} className="preview_menu_opt">
              <span>Добавить макет</span>
              <img src={process.env.PUBLIC_URL + "/img/menu/plus.png"} alt="" />
            </div>
            <NavLink to={`/editing/${params.id}`} className="preview_menu_opt">
              <span>Редактировать</span>
              <img src={process.env.PUBLIC_URL + "/img/menu/reda.png"} alt="" />
            </NavLink>
            <div
              onClick={() => setPrevon(!prevon)}
              className="preview_menu_opt"
            >
              <span>Изменить превью</span>
              <img src={process.env.PUBLIC_URL + "/img/menu/fese.png"} alt="" />
            </div>
            <div onClick={() => Dostup(params.id)} className="preview_menu_opt">
              <span>Изменить доступ</span>
              {params.status == 1 ? (
                <img
                  src={process.env.PUBLIC_URL + "/img/menu/dost.png"}
                  alt=""
                />
              ) : (
                <img
                  className="eye"
                  src={process.env.PUBLIC_URL + "/img/menu/eye.png"}
                  alt=""
                />
              )}
            </div>
          </div>
           <div  className="preview_delete">
           <div onClick={() => setDel(!del)} className="close-container">
             <div className="leftright"></div>
             <div className="rightleft"></div>
             <label className="close">Удалить</label>
           </div>
         </div></>
        ) : (
          ""
        )}

       
      </div>
      <MaketUP id={params.id} on={on} setOn={setOn} />
      <PrevievUP id={params.id} on={prevon} setOn={setPrevon} />
      <DeleteProject id={params.id} on={del} setOn={setDel} />
    </>
  );
}
function CatId(params) {
  switch (params.cat) {
    case "1":
      return (
        <p
          style={{
            background: params.theme.backgroundList,
            color: params.theme.colorList,
          }}
          className="preview_list-item"
        >
          Лендинг
        </p>
      );
    case "2":
      return (
        <p
          style={{
            background: params.theme.backgroundList,
            color: params.theme.colorList,
          }}
          className="preview_list-item"
        >
          Интернет-магазин
        </p>
      );
    case "3":
      return (
        <p
          style={{
            background: params.theme.backgroundList,
            color: params.theme.colorList,
          }}
          className="preview_list-item"
        >
          Корп. сайт
        </p>
      );
    case "4":
      return (
        <p
          style={{
            background: params.theme.backgroundList,
            color: params.theme.colorList,
          }}
          className="preview_list-item"
        >
          Промосайт
        </p>
      );
    case "6":
      return (
        <p
          style={{
            background: params.theme.backgroundList,
            color: params.theme.colorList,
          }}
          className="preview_list-item"
        >
          Перспективные проекты
        </p>
      );
    default:
      break;
  }
}
