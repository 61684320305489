  import { useEffect, useState } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import { Header } from "../../components/header/header";
  import { PreviewMini } from "../../components/saite_prev/PreviewMini";
  import { todosSelector } from "../../stor/selectors";
  import { allPrev } from "../../stor/thunk";

  import "./style.css";
  import { useThemeContext } from "../../context/theme";
  import Fon from "../../components/fon";
import Preloader from "../preloader/preloader";

  export function Main(params) {
    const { theme } = useThemeContext();

    const [state, setstate] = useState(1);
    const [filter, setFilter] = useState("");
    const [mass, setMass] = useState("");
    const [chek, setChek] = useState(0);

    const discpatch = useDispatch();
    const data = useSelector(todosSelector);
    function Nex() {
      setstate(state + 1);
    }

    useEffect(() => {
      if (!data.all) {
        discpatch(allPrev());
      }

      setMass(data.all);
    }, [data]);
    if (mass === null) {
      return <Preloader/>;
    }
    if (mass == null) {
      return <Preloader/>;
    }
    // return <Preloader/>;


    return (
      <div style={{ background: theme.background, color: theme.color }} >
        <div  className="container">
        <Header />
        <main style={{ background: theme.background, color: theme.color }} className="main ">
          <div className="filter">
              <div className="filter_chek">
                  <div   onClick={()=>setChek(0)} className={chek==0?"filter_chek_item activ_chek":"filter_chek_item "}>
                  Все
                  </div>
                  <div  onClick={()=>setChek(1)} className={chek==1?"filter_chek_item activ_chek":"filter_chek_item "}>
                  Лендинг
                  </div>
                  <div  onClick={()=>setChek(2)} className={chek==2?"filter_chek_item activ_chek":"filter_chek_item "}>
                  Интернет-магазин
                  </div>
                  <div  onClick={()=>setChek(3)} className={chek==3?"filter_chek_item activ_chek":"filter_chek_item "}>
                  Корп. сайт
                  </div>
                  <div  onClick={()=>setChek(4)} className={chek==4?"filter_chek_item activ_chek":"filter_chek_item "}>
                  Промосайт
                  </div>
                  <div onClick={()=>setChek(6)} className={chek==6?"filter_chek_item activ_chek":"filter_chek_item "}>
                  Перспективные проекты
                  </div>
                  <div onClick={()=>setChek(5)} className={chek==5?"filter_chek_item activ_chek":"filter_chek_item "}>
                  Остальное
                  </div>
              </div>
            <div className="search">
              <img
                className="search_img"
                src={process.env.PUBLIC_URL + "/search.svg"}
                alt=""
              />
              <input
                onChange={(event) => setFilter(event.target.value)}
                className="search_failed"
                type="text"
                placeholder="Введите название проекта"
              />
            </div>
          </div>
          <div className="conteiner">
            <div className="main_gallari">
              {/* {theme.them && <Fon/> } */}
              {mass ? <Gallery chek={chek} theme={theme} value={filter} mass={mass} state={state} /> : ""}
            </div>
            {mass.length >= 12 && (
              <div style={{ background: theme.backgroundButtom, color: theme.colorButtom }}  onClick={() => Nex()} className="main_box">
                Загрузить ещё
              </div>
            )}
            <div
              onClick={() => {
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
              className="upWind" style={{ background: theme.backgroundList, color: theme.colorList }}
            >
              <img
                className="upWind_img"
                src={process.env.PUBLIC_URL + "/up1.svg"}
                alt=""
              />
              <img
                className="upWind_img2"
                src={process.env.PUBLIC_URL + "/up2.svg"}
                alt=""
              />
              
            </div>
          </div>
        </main>
      </div>
      </div>
      
    );
  }
  const Gallery = ({ value, mass, state,theme, chek }) => {
    let filterMass = mass.filter((element) => {
      let catt = element.cat_id.split(",");
      if (localStorage.getItem('id_user')) {
        if (chek===0) {
          return element.name.toLowerCase().includes(value.toString().toLowerCase());
        }else if (catt.includes(String(chek))) {
          return element.name.toLowerCase().includes(value.toString().toLowerCase());
          
        }
        
        
      }else if (element.status!=1) {
        if (chek===0) {
          return element.name.toLowerCase().includes(value.toString().toLowerCase());
        }else if (catt.includes(String(chek))) {
          return element.name.toLowerCase().includes(value.toString().toLowerCase());
          
        }
      }
      
      
    });
    
    
    return filterMass
      .slice(0, 12 * state)
      .map((element, id) => (
        element.previw ===null?
        <PreviewMini
          theme={theme}
          key={id}
          id={element.id}
          user={element.user_id}
          img={"no_image.jpg"}
          cat_id={element.cat_id}
          name={element.name}
          status={element.status}
        />:
        <PreviewMini
          theme={theme}
          key={id}
          id={element.id}
          user={element.user_id}
          img={element.translit+"/"+element.previw}
          cat_id={element.cat_id}
          name={element.name}
          status={element.status}
        />
      ));
  };
