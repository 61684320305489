import React, { Component } from "react";

import ReactToPrint from "react-to-print";

class Prints extends Component {
  render() {
    return (
      <div>
        <ReactToPrint
          trigger={() => {
            return <button className="entrance">Распечатать</button>;
          }}
          content={() => this.componentRef}
          documentTitle={this.props.name}
          pageStyle="print"
          
        />
        <div className="printPopup" ref={(el) => (this.componentRef = el)}>

            <img className="titlePrint"
              src={process.env.PUBLIC_URL + "/header-IP.png"}
              alt="logo"
            />
          
            <img className="imgPrint" src={this.props.img} alt="Макет" />
            <img className="textPrint" src={process.env.PUBLIC_URL + "/sogl.jpg"} alt="Макет" />

        </div>
      </div>
    );
  }
}

export default Prints;
